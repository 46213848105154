import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';


const Sidebar = ({links, miniSidebar}) => {

    return (
        <>
        <div className={`transition transform ${!miniSidebar ? "-translate-x-full" : "-translate-x-0"} lg:translate-x-0 h-screen fixed top-16 lg:top-20  left-0 bottom-0 w-64 lg:w-250 2xl:w-300 bg-gray-bg-dark overflow-y-auto`}>
            {links.map((link,index) => (
                <Link key={index} to={link.to} className="flex cursor-pointer items-center hover:bg-gray-dark  border-b-2 border-gray-dark px-8 py-5">
                    <span className="pr-3">{link.icon}</span>
                    <h1 className="text-lg">{link.title}</h1>
                </Link>
            ))}
        </div>   
        </>
    )
}

export default Sidebar
