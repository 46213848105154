import { configureStore } from '@reduxjs/toolkit';

// Slice imports
// user 
import userReducer from './slices/userSlice';
// user 

import addQuestionReducer from "./slices/addQuestionSlice"
import questionListReducer from './slices/questionListSlice';
import attemptQuestionsReducer from './slices/attemptQuestionsSlice';
import traineeQuestionnaireTabReducer from './slices/traineeQuestionnaireTabSlice';
import traineeReviewQuestionnaireTabReducer from './slices/traineeQuestionnaireTabSlice';
// Slice imports


export default configureStore({
    devTools: process.env.NODE_ENV === 'development' ? true : false,
    reducer:{
        // auth user
        user: userReducer,
        // auth user
        add_question: addQuestionReducer,
        question_list: questionListReducer,
        
        attempt_questions: attemptQuestionsReducer,
        trainee_questionnaire_tab: traineeQuestionnaireTabReducer,
        trainee_review_questionnaire_tab: traineeReviewQuestionnaireTabReducer
       
    } 
})


