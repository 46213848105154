
import { AiFillDashboard } from "react-icons/ai";
import { FaBook } from "react-icons/fa";
import { BiCalendarPlus, BiBarChartAlt } from "react-icons/bi";
import {BsFillPencilFill} from 'react-icons/bs';
import { FaEnvelope } from "react-icons/fa";
import {FaUsers} from 'react-icons/fa';

export const userLinks = [
    {
        title: "Dashboard",
        to:"/dashboard",
        icon:<AiFillDashboard />
    },
    {
        title: "Tests",
        to:"/trainings",
        icon:<FaBook />
    },
    {
        title: "Test your knowledge",
        to:"/review-tests",
        icon:<FaBook />
    },
    // {
    //     title: "Statistics",
    //     to:"/statistics",
    //     icon:<BiBarChartAlt />
    // },
]