import React, {Suspense} from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import {FullScreenLoading} from "../components/Loading";

//pages
const Dashboard = React.lazy(() => import("../Pages/Dashboard"));
const Profile = React.lazy(() => import("../Pages/Profile"));
const Courses = React.lazy(() => import("../Pages/Courses"));

//training
const TrainingListing = React.lazy(() => import("../Pages/Training/Listing"));
const TrainingDetail = React.lazy(() => import("../Pages/Training/Detail"));
const TrainingCreate = React.lazy(() => import("../Pages/Training/Create"));
const TrainingUpdate = React.lazy(() => import("../Pages/Training/Update"));
//training

//questionnaire
const QuestionnaireCreate = React.lazy(() => import("../Pages/Questionnaire/Create"));
const QuestionnaireDetail = React.lazy(() => import("../Pages/Questionnaire/Detail"));
const QuestionnaireUpdate = React.lazy(() => import("../Pages/Questionnaire/Update"));
// const QuestionnaireListing = React.lazy(() => import("../Pages/Questionnaire/Listing"));
//questionnaire

// Review Questionnaire
const ReviewQuestionnaireListing = React.lazy(() => import("../Pages/ReviewQuestionnaire"));
const ReviewQuestionnaireDetail = React.lazy(() => import("../Pages/ReviewQuestionnaire/Detail"));
const ReviewQuestionnaireCreate = React.lazy(() => import("../Pages/ReviewQuestionnaire/Create"));
const ReviewQuestionnaireUpdate = React.lazy(() => import("../Pages/ReviewQuestionnaire/Update"));

// Review Questionnaire

//trainee listing global
const TraineeTopListing = React.lazy(() => import("../Pages/TraineeTopListing"))
//trainee listing global
const TraineeResults = React.lazy(() => import("../Pages/TraineeResults"));

function AdminRoutes(props) {
  const location = useLocation()

    return (
        <>
        <Suspense fallback={<FullScreenLoading/>}>
          <Switch>

          <Route path='/profile' exact>
            <Profile />
          </Route>
          <Route path='/dashboard' exact>
            <Dashboard/>
          </Route>
          {/* <Route path='/courses' exact>
            <Courses/>
          </Route> */}

          {/* trainings routes below */}
            <Route path='/trainings' exact>
              <TrainingListing/>
            </Route>
            <Route path='/trainings/create/' exact>
              <TrainingCreate/>
            </Route>
            <Route path='/trainings/update/:id' exact>
              <TrainingUpdate/>
            </Route>
            <Route path='/trainings/:id' exact>
              <TrainingDetail/>
            </Route>
          {/* trainings routes below */}
          
          {/* questionnaire routes below */}
            {/* <Route path='/questionnaires' exact>
              <QuestionnaireListing/>
            </Route> */}
            <Route path='/tests/create' exact>
              <QuestionnaireCreate/>
            </Route>
            <Route path='/tests/create/:trainingId' exact>
              <QuestionnaireCreate/>
            </Route>
            <Route path='/tests/:id' exact>
              <QuestionnaireDetail/>
            </Route>
            <Route path='/tests/update/:id' exact>
              <QuestionnaireUpdate/>
            </Route>
          {/* questionnaire routes below */}


          {/* Review Question routes below */}
          <Route path='/review-tests' exact>
              <ReviewQuestionnaireListing/>
          </Route>
          <Route path='/review-tests/create' exact>
              <ReviewQuestionnaireCreate/>
          </Route>
          <Route path='/review-tests/:id' exact>
            <ReviewQuestionnaireDetail/>
          </Route>
          <Route path='/review-tests/update/:id' exact>
              <ReviewQuestionnaireUpdate/>
          </Route>
          {/* Review Question routes above */}

          {/* trainee global top level listing */}
          <Route path="/trainees" exact>
            <TraineeTopListing />
          </Route>
          {/* trainee global top level listing */}
         
          <Route path='/tests/:questionnaireId/result/:attemptId/:traineeId' exact>
            <TraineeResults/>
          </Route>

          <Redirect from="/" to="/dashboard"/>
          </Switch>

          {/* <Footer /> */}
        </Suspense>
        </>
    )
}

export default AdminRoutes
