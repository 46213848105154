import { createSlice } from '@reduxjs/toolkit';
import { getAuthUser } from '../../utils';



const initialState = getAuthUser();

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state, action) => (action.payload),
        logout: (state,action) => {
            // localStorage.removeItem('access_token');
            return false;
        },
        update_name: (state,action) => {
            state.first_name=action.payload.first_name
            state.last_name=action.payload.last_name
            return state;
        }
    }
})

export const {login,logout, update_name} = userSlice.actions;
export default userSlice.reducer;
