import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import BaseRoutes from './routes/BaseRoute';

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import { Provider } from 'react-redux';
import store from './redux/store';
import { login } from "./redux/slices/userSlice";

import { getAuthUser } from './utils';
import BasePopup, {BasePopupContext} from './components/BasePopup';

const App = () => {

  // state for popup
  const initialPopupState = {
    component: "",
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
  }
  const [popupState, setPopupState] = useState(initialPopupState);

  const openPopup = (params) => setPopupState(old => ({...old, ...params}));

  const global_popup_context_data = {
    popupState,
    setPopup: openPopup
  }
  // state for popup

  useEffect(() => {
    let authUser = getAuthUser()
    if (authUser) {
      store.dispatch(login(authUser));
    }
  }, [])
  return (
    <>
      <Provider store={store}>
        <BasePopupContext.Provider value={global_popup_context_data}>
          <BrowserRouter>
            <ReactNotification />
            <BasePopup/>
            {/* main application routes here */}
            <BaseRoutes />
            {/* main application routes here */}
          </BrowserRouter>
        </BasePopupContext.Provider>
      </Provider>
    </>
  );
}

export default App;
