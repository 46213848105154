// export const BASE_URL = "http://192.168.18.29:8001/"

// export const BASE_URL = "https://questionaire-dev-api.astutesoftwares00.com/"

// live live
// export const BASE_URL = "https://questionaire.astutesoftwares.com/"

export const BASE_URL = "https://backend-questionnaire.corcym.com/"
// export const BASE_URL = "http://192.168.10.16:8001/"

// export const BASE_URL = "https://351a-117-53-40-30.ngrok-free.app/"

export const ADMIN_ROLE = "Super Admin"
export const TRAINEE_ROLE = "Trainee"

export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE__DISPLAY_FORMAT = "dd-MM-yyyy";

export const LIST_LIMIT = 6;

export const QUESTION_TYPE = [
    {
        value: "free",
        label: "Free Text"
    },
    {
        value: "mcq",
        label: "Multiple Choice Questions"
    }
]

export const QUESTION_MCQ = "mcq";

export const SERVER_FREE_TEXT = "free-text";
export const SERVER_MCQ = "radio-button";

export const DEFAULT_QUESTION_ADD_OPTION = {
    title: {
        value: "",
        error: false
    },
    is_true: false,
    reason: {
        value: "",
        error: false
    }
}

export const truncateText = (text) => {
    if(text.length < 200){
        return text
    } else {
        return text.substr(0,200) + "..."
    }
}

// custom styles
export const tableStyles = {
    rows: {
        style: {
        minHeight: '50px', // override the row height
        }
    },
    headCells: {
        style: {
            "&:hover": {
                color:"white"
            },
            backgroundColor: "#043c7b",
            color: "white",
            padding: "10px 10px",
            fontWeight: "light",
            fontSize: "15px",
            borderRight: "1px solid #ddd",
            borderLeft: "1px solid #ddd",
            textAlign: "left"
            
        },

        activeSortStyle: {
            color: "white",
            '&:focus': {
                outline: 'none',
            },
            '&:hover:not(:focus)': {
                color: "white",
            },
        },
        inactiveSortStyle: {
            '&:focus': {
            outline: 'none',
            color: "white",
            },
            '&:hover': {
                color: "white",
            },
        },
    },
    cells: {
        style: {
            borderBottom: "1px solid #ddd",
            borderRight: "1px solid #ddd",
            borderLeft: "1px solid #ddd",
            margin: "0",
            width:"150px"

        },
    },
};

export const QUESTION_LIMIT = 20;

export const REVIEW_QUESTION_LIMIT = 1;

export const STATUS_OPTIONS = [
    {
        value: "active",
        label: "Active"
    },
    {
        value: "disabled",
        label: "Disabled"
    },
    {
        value: "pending",
        label: "Pending"
    },
]