import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const traineeQuestionnaireTab = createSlice({
    name: "trainee_questionnaire_tab",
    initialState,
    reducers: {
        set_trainee_questionnaire_tab: (state, action) => (action.payload),
    }
})

export const {set_trainee_questionnaire_tab} = traineeQuestionnaireTab.actions;
export default traineeQuestionnaireTab.reducer;
