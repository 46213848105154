import React, { Suspense } from 'react'
import { Switch,Route, Redirect } from 'react-router-dom';

import {FullScreenLoading} from "../components/Loading";

const Login = React.lazy(() => import("../Pages/Login"));

const ActivateAccount = React.lazy(() => import("../Pages/ActivateAccount"));
const ForgotPassword = React.lazy(() => import("../Pages/ForgotPassword"));


function AuthRoute() {

    return (
    <Suspense fallback={<FullScreenLoading/>}>
        <Switch>
            <Route path="/auth" exact>
                <Login />
            </Route>

            <Route path="/activate-account/:token" exact>
                <ActivateAccount first={true}/>
            </Route>
            <Route path="/reset-password/:token" exact>
                <ActivateAccount />
            </Route>
            {/* 
            <Route path="/recover-password/:token" exact>
                <ActivateAccount />
            </Route>
            <Route path="/feedback/:id" exact>
                <EventFeedbackForm />
            </Route> */}

            <Route path="/forgot-password" exact>
                <ForgotPassword />
            </Route>



            <Redirect from="/" to="/auth" />
        </Switch>
    </Suspense>
    )
}

export default AuthRoute
