import React, {Suspense} from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import {FullScreenLoading} from "../components/Loading";
//pages
const Dashboard = React.lazy(() => import("../Pages/Dashboard/TraineeDashboard"));
const Profile = React.lazy(() => import("../Pages/Profile"));

const AttemptQuestionnaire = React.lazy(() => import("../Pages/AttemptQuestionnaire"));
const TraineeResults = React.lazy(() => import("../Pages/TraineeResults"));

const TrainingListing = React.lazy(() => import("../Pages/Training/Listing"));
const TrainingDetail = React.lazy(() => import("../Pages/Training/Detail"));

const ReviewQuestionnaireListing = React.lazy(() => import("../Pages/ReviewQuestionnaire"));
const ReviewQuestionnaireDetail = React.lazy(() => import("../Pages/TraineeReviewQuestionnaireDetail"));
const AttemptReviewQuestionnaire = React.lazy(() => import("../Pages/AttemptReviewQuestionnaire"));




const QuestionnaireDetail = React.lazy(() => import("../Pages/TraineeQuestionnaireDetail"));

function UserRoutes(props) {

    return (
        <>
        <Suspense fallback={<FullScreenLoading/>}>
          <Switch>

          <Route path='/profile' exact>
            <Profile />
          </Route>

          <Route path='/dashboard' exact>
            <Dashboard/>
          </Route>
          {/* trainings routes below */}
          <Route path='/trainings' exact>
              <TrainingListing/>
            </Route>
          <Route path='/trainings/:id' exact>
            <TrainingDetail/>
          </Route>
          {/* trainings routes above */}

          {/* Review Question routes below */}
          <Route path='/review-tests' exact>
              <ReviewQuestionnaireListing/>
          </Route>
          <Route path='/review-tests/:id' exact>
            <ReviewQuestionnaireDetail/>
          </Route>
          <Route path='/review-tests/:id/attempt' exact>
            <AttemptReviewQuestionnaire/>
          </Route>
          {/* Review Question routes below */}

          {/* questionnaires routes below */}
          <Route path='/tests/:id' exact>
            <QuestionnaireDetail/>
          </Route>
          {/* questionnaires routes above */}
          
          <Route path='/tests/:id/attempt' exact>
            <AttemptQuestionnaire/>
          </Route>

          <Route path='/tests/:questionnaireId/result/:attemptId' exact>
            <TraineeResults/>
          </Route>
         
          <Redirect from="/" to="/dashboard"/>
          
          </Switch>

          {/* <Footer /> */}
        </Suspense>
        </>
    )
}

export default UserRoutes
