import { store } from "react-notifications-component";
import * as yup from "yup";
import DOMPurify from 'dompurify';

import draftToHtml from 'draftjs-to-html';
import { convertToRaw, ContentState, convertFromHTML, EditorState } from 'draft-js';


export const getAuthUser = () => {
    let token = localStorage.getItem("access_token");
    if(token){
        return JSON.parse(token).user;
    } else{
        return false;
    }
}

export const getToken = () => {
    let token = localStorage.getItem("access_token");
    if(token){
        return JSON.parse(token).access_token;
    } else{
        return false;
    }
}

export const setToken = (access_token) => {
    localStorage.setItem("access_token", JSON.stringify(access_token));
}

export const removeToken =()=>{
    return localStorage.removeItem("access_token")
}

export const success_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const error_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const warning_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export function stringNotEmpty() {
    return yup.mixed().test({
        name: 'stringNotEmpty',
        exclusive: false,
        message: "Required",
        test: function(value) {
            if(value !== undefined){
                return value.trim() !== "";
            } else if(value === undefined){
                return false
            }
        },  
    });
}

export function add_suffix_number(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export const getRawContentFromHtml = (content) => {
    return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(content)
        ))
}

export const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data)
})


export const update_name_storage=(first_name,last_name)=>{
    let Storageobject = localStorage.getItem("access_token");
    let parsedLocalStorageObject=JSON.parse(Storageobject);
    parsedLocalStorageObject.user.first_name=first_name;
    parsedLocalStorageObject.user.last_name=last_name;
    localStorage.setItem("access_token", JSON.stringify(parsedLocalStorageObject))

    // localStorage.setItem("access_token",  JSON.stringify({...localStorage.getItem("access_token"), ...parsedLocalStorageObject}));
    // localStorage.setItem("access_token", JSON.stringify({...Storageobject, ...parsedLocalStorageObject.user.first_name=first_name,...parsedLocalStorageObject.user.last_name=last_name }));



    
    // console.log(`object : ${JSON.parse(Storageobject).user.first_name}`)
    // console.log(`object : ${}`)
}


export const selectStylesFilter = error => (
    {
        
        control: (provided, state) => ({
            ...provided,
            height: "12px",
            fontSize: "12px",
            borderRadius: "0.375rem",
            
        }),
        
        option: (provided, state) => {
            return ({
                ...provided,
                fontSize: "12px",
            })
        },
        
        singleValue: (provided, state) => {
            return { ...provided,  fontSize: "12px" };
        }
    }
)