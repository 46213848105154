import {createSlice} from "@reduxjs/toolkit";
import { DEFAULT_QUESTION_ADD_OPTION, QUESTION_TYPE } from "../../constants";

const initialState = {
    title: {
        value: "",
        error: false,
    },
    options: [
        DEFAULT_QUESTION_ADD_OPTION,
        DEFAULT_QUESTION_ADD_OPTION,
    ],
    question_type: QUESTION_TYPE[0],
    multiple_correct: false
}

const addQuestionSlice = createSlice({
    name: "add_question",
    initialState,
    reducers: {
        //titles below
        set_title_value: (state, action) => {
            state.title.value = action.payload;
            if(!action.payload || !action.payload.trim()){
                state.title.error = true;
            } else{
                state.title.error = false
            }
            return state
        },
        set_title_error: (state, action) => {
            state.title.error = true;
            return state
        },
        //titles above
        set_question_type: (state, action) => {
            state.question_type = action.payload;
            return state
        },

        reset_options: (state, action) => {
            state.options = initialState.options
            return state
        },
        add_new_option: (state, action) => {
            state.options.push(DEFAULT_QUESTION_ADD_OPTION)
            return state
        },

        remove_option: (state, action) => {
            state.options = state.options.filter((item, index) => action.payload !== index)
            return state
        },

        set_option_title: (state, action) => {
            const index = action.payload.index;
            state.options[index].title.value = action.payload.value
            if(!action.payload.value || !action.payload.value.trim()){
                state.options[index].title.error = true;
            } else{
                state.options[index].title.error = false
            }
            return state
        },
        
        set_option_error: (state, action) => {
            const index = action.payload;
            state.options[index].title.error = true
            return state
        },
        set_option_reason: (state, action) => {
            const index = action.payload.index;
            state.options[index].reason.value = action.payload.value
            if((!action.payload.value || !action.payload.value.trim()) && state.options[index].is_true){
                state.options[index].reason.error = true;
            } else{
                state.options[index].reason.error = false
            }
            return state
        },
        
        set_option_reason_error: (state, action) => {
            const index = action.payload;
            state.options[index].reason.error = true
            return state
        },

        set_option_is_true: (state, action) => {
            const index = action.payload.index;
            state.options = state.options.map((item, i) => {
                return {
                    ...item,
                    is_true: i === index ? !state.options[index].is_true : false,
                    reason: {
                        value: "",
                        error: false
                    }
                }
            });
        },
        set_option_is_true_multiple: (state, action) => {
            const index = action.payload.index;
            state.options[index].is_true = !state.options[index].is_true
            state.options[index].reason = {value: "", error: false}
            return state;
        },
        reset_option_is_true: (state, action) => {
            state.options = state.options.map(item => ({...item, is_true: false, reason: {value: "", error: false}}))
            return state
        },

        set_multiple_correct: (state, action) => {
            state.multiple_correct = action.payload
            return state
        },
        reset_add_question: (state, action) => {
            return initialState;
        },

        populate_add_question_dropdown: (state, action) => {
            return {...state, ...action.payload}; //we are assuming that we are sending the correct data after properly formatting it
        }

    }
})

export const {
    set_title_value,
    set_title_error,
    set_question_type,
    reset_options,
    add_new_option,
    remove_option,
    set_option_title,
    set_option_error,
    set_option_is_true,
    reset_option_is_true,
    set_option_is_true_multiple,
    set_multiple_correct,
    set_option_reason,
    set_option_reason_error,
    reset_add_question,
    populate_add_question_dropdown
} = addQuestionSlice.actions;

export default addQuestionSlice.reducer;