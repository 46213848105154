
import { AiFillDashboard } from "react-icons/ai";
import { FaBook } from "react-icons/fa";
import { BiBarChartAlt } from "react-icons/bi";
import {BsFillPencilFill} from 'react-icons/bs';
import {FaUsers} from 'react-icons/fa';



export const adminLinks = [
    {
        title: "Dashboard",
        to:"/dashboard",
        icon:<AiFillDashboard />
    },
    {
        title: "Tests",
        to:"/trainings",
        icon:<FaBook />
    },
    {
        title: "Create Test",
        to:"/trainings/create",
        icon:<BsFillPencilFill />
    },
    {
        title: "Trainees",
        to:"/trainees",
        icon:<FaUsers />
    },
    {
        title: "Test your knowledge",
        to:"/review-tests",
        icon:<FaBook />
    },
    // {
    //     title: "Trainees",
    //     to:"/trainees",
    //     icon:<FaUsers />
    // },
    // {
    //     title: "Statistics",
    //     to:"/statistics",
    //     icon:<BiBarChartAlt />
    // },
    
    // {
    //     title: "Send Invite",
    //     to:"/send-invitation",
    //     icon:<FaEnvelope />
    // }
]