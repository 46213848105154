import React,{useState} from 'react';
import {Link} from 'react-router-dom';

const MiniSidebar = ({links,miniSidebar}) => {

    const [linkInfo, setLinkInfo] = useState(false);
    const [title,setTitle] = useState('');
    const showLinkInfo = (title) => {
        setLinkInfo(true);
        setTitle(title);
    }
  return (
    <>
    <div className={`transition transform ${miniSidebar ? "-translate-x-full" : "-translate-x-0"} lg:translate-x-0 h-screen fixed top-16 lg:top-20  left-0 bottom-0    bg-gray-bg-dark overflow-y-auto z-10`}>
        {links.map((link,index) => (
        <div className="" key={index}>
            <Link to={link.to} onMouseLeave={()=>{setLinkInfo(false)}} onMouseEnter={()=>{showLinkInfo(link.title)}} className="flex cursor-pointer  items-center hover:bg-gray-dark  border-b-2 border-gray-dark px-5 py-5">
                <span className="px-1">{link.icon}</span>
            </Link>
            {/* {
                linkInfo?
                <h1>{link.title}</h1>
                :
                null
            } */}
        </div>
        ))}
    </div>
    </>
  )
};

export default MiniSidebar;
