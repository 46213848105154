import React from 'react';

import { Link } from 'react-router-dom';
// icon imports
import { AiFillDashboard } from "react-icons/ai";
import {CgProfile} from 'react-icons/cg';
import {BiLogOut} from 'react-icons/bi';

// redux imports
import {useSelector,useDispatch} from 'react-redux';
import { logout } from '../../redux/slices/userSlice';


const LogoutCard = () => {

  const dispatch = useDispatch();

  const cardLinks = [
    {
      title: "Dashboard",
      to:"/dashboard",
      icon:<AiFillDashboard />
    },
    {
      title: "Profile",
      to:"/profile",
      icon:<CgProfile />
    }
  ]
  return (
    <div className="absolute top-16 w-250 font-medium text-lg  shadow-sm right-10 bg-gray-bg-dark z-10">
      {
        cardLinks.map(link => (
          <Link to={link.to} className="flex cursor-pointer items-center hover:bg-gray-dark py-3 px-5 ">
              <span className="pr-3">{link.icon}</span>
              <h1 className="text-gray-text ">{link.title}</h1>
          </Link>
        ))
      }
      <div onClick={()=>{
        dispatch(logout())
        localStorage.removeItem('access_token');
        }} className="flex cursor-pointer items-center hover:bg-gray-dark py-3 border-t-2 px-5 mt-3 ">
          <span className="pr-3"><BiLogOut /></span>
          <h1 className="text-gray-text ">Logout</h1>
      </div>
        
    </div>
  );
};

export default LogoutCard;
