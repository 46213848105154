import React from 'react'
import {BiLoaderAlt} from "react-icons/bi";

const Loading = () => {
    return (
        <div className="flex justify-center items-center my-12">
            <span className="text-7xl text-gray-sidebar">
                <BiLoaderAlt className="animate-spin"/>
            </span>
        </div>
    )
}

export default Loading

export const FullScreenLoading = () => {
    return (
        <div className="loader-screen left-0 top-0 fixed h-screen w-screen bg-white flex items-center justify-center" style={{zIndex: 10000}}>
            <h2 className="animate-spin text-7xl text-red-primary"><BiLoaderAlt /></h2>
        </div>
    )
} 
