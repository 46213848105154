import React from 'react'
import { useSelector } from 'react-redux';
import {Link} from "react-router-dom";
import {TRAINEE_ROLE} from "../../constants"
const Footer = ({color}) => {
    const user = useSelector(state => state.user);
    return (
        <>
                <div className="w-full flex-shrink-0">
                    {/* container design start */}
                    <div className="h-16 md:h-24 bg-repeat-x bg-contain" style={{backgroundImage: "url(/images/pattern.svg)"}}>
                        <div className={`w-1/4 h-full`}></div>
                    </div>
                    {/* container design end */}

                    {/* container content start */}
                    <div className="relative bg-blue-primary px-8 lg:px-20 py-10">

                        {/* news grid start */}
                        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-8 gap-y-4 lg:gap-y-0">
                           {/* grid item start */}
                           {/* <div className="border-l-4 border-red-primary px-4 grid-col-span-1">
                                <Link to="/contact-us" className="block text-gray-lighter text-sm xl:text-base capitalize mb-3">
                                    Contact us
                                </Link>
                                <Link to="/customer-service" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter  lg:mb-3">
                                    Customer Service
                                </Link>
                                <Link to="/research" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter lg:mb-3">
                                    Investigator Initiated Research
                                </Link>
                                <Link to="/donations-and-grants" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter lg:mb-3">
                                    Donations and Grants
                                </Link>
                                <Link to="/product-safety" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter  lg:mb-3">
                                    Product Safety
                                </Link>
                            </div> */}
                           {/* grid item end */}

                           {/* grid item start */}
                           <div className="border-l-4 border-red-primary px-4 grid-col-span-1">
                                <a target="_blank" href="https://corcym.com/terms" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter lg:mb-3">
                                    Terms of use
                                </a>
                                <a target="_blank" href="https://corcym.s3.eu-central-1.amazonaws.com/Corcym_Privacy_Policy_+Training+survey-1-2.pdf" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter  lg:mb-3">
                                    Privacy Policy
                                </a>
                                <a target="_blank" href="https://corcym.com/cookies" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter  lg:mb-3">
                                    Cookie Policy
                                </a>
                                <a target="_blank" href="https://corcym.com/legal" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter lg:mb-3">
                                    Legal Notes
                                </a>
                            </div>
                           {/* grid item end */}

                           {user.role === TRAINEE_ROLE &&
                                <p className="lg:col-span-2 lg:text-right text-xs text-white text-left">
                                    For any kind of technical support, please write us at: <a href="mailto:communication@corcym.com" className='cursor-pointer underline'>communication@corcym.com</a>
                                </p>
                            }   

                           {/* grid item start */}
                           {/* <div className="border-l-4 border-red-primary px-4 lg:col-start-4">
                                <div className="mb-6">
                                    <p className="font-bold text-gray-lighter text-xs md:text-sm lg:text-base  mb-1 lg:mb-2">
                                    Corcym Srl
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                    Via Crescentino sn
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                    13040 Saluggia (VC) Italy
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                    Теl: +39 0161 487800
                                    </p>
                                </div>
                                <div className="mb-6">
                                    <p className="font-bold text-gray-lighter text-xs md:text-sm lg:text-base  mb-1 lg:mb-2">
                                        Corcym Canada Corp.
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                        5005 North Fraser Way
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                        Burnaby, BC V5J 5M1 Canada
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                        <a href="tel:+6044125650">Tel: +604 412-5650</a>
                                    </p>
                                </div>

                            </div>  */}
                        </div> 
                        {/* news grid end */}
                        
                        <p className="text-xs text-white text-left mt-5">
                        Corcym UK Limited, Registered No. 13139307 - 18 St. Swithin's Lane, London, EC4N 8AD, United Kingdom
                        </p>
                        <p className="text-xs text-white text-right mt-5 italic ">
                        Designed and developed by <a className="underline" href="https://www.kaffeina.it">Kaffeina.it</a>
                        </p>


                    </div>
                    {/* container content end */}
                    {/* section flex end */}


                </div>
        </>
    )
}

export default Footer
