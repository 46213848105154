import {createSlice} from '@reduxjs/toolkit';

const initialState =  [

]

const questionListSlice = createSlice({
    name: "question_list",
    initialState,
    reducers: {
        set_questions: (state, action) => {
            return action.payload
        },
        remove_question: (state, action) => {
            return state.filter(item => item.id !== action.payload);
        },
        reset_questions: (state, action) => {
            return initialState
        },
        set_edit_open: (state, action) => {
            const index = action.payload;
            state = state.map((item, i) => (
                {...item, edit: index === i ? true : false }
            ));
            return state
        },
        set_edit_close: (state, action) => {
            state = state.map(item => (
                {...item, edit: false}
            ));
            return state
        },
        set_update_question: (state, action) => {
            const index = state.findIndex(item => item.id === action.payload.id)
            if(index !== -1){
                state[index] = action.payload.data;
            }
            return state
        },
        set_add_question: (state, action) => {
            state.push(action.payload);
            return state
        }
    }
})

export const {
    set_questions,
    reset_questions,
    remove_question,
    set_edit_open,
    set_edit_close,
    set_update_question,
    set_add_question
} = questionListSlice.actions;

export default questionListSlice.reducer;