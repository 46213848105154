import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    questions: [],
    started: true,
    currentQuestion: 0,
    resultCount: 0
}

const attemptQuestionsSlice = createSlice({
    name: "attempt_questions",
    initialState,
    reducers: {
        set_questions: (state, action) => {
            state.questions = action.payload
        },
        set_current_question: (state, action) => {
            state.currentQuestion = action.payload
        },
        set_result_count: (state, action) => {
            state.resultCount = action.payload
        },
        set_started: (state, action) => {
            state.started = true
        },
        set_text_answer: (state, action) => {
            const index = action.payload.index;
            const value = action.payload.value
            state.questions[index].text_answer.value = value;
            if(!value || !value.trim()){
                state.questions[index].text_answer.error = true;
            } else{
                state.questions[index].text_answer.error = false;
            }
            return state
        },
        
        set_single_option: (state, action) => {
            const index = action.payload.index;
            const value = action.payload.value

            // first set all selected to false
            state.questions[index].option.map(o => {
                o.selected = false;
                return o
            });

            // now set the selected to true
            const optionIndex = state.questions[index].option.findIndex(o => o.id === value);
            state.questions[index].option[optionIndex].selected = true;
            state.questions[index].option[optionIndex].error = false;

            return state
        },
        set_multiple_option: (state, action) => {
            const index = action.payload.index;
            const value = action.payload.value

            // now set the selected to true
            const optionIndex = state.questions[index].option.findIndex(o => o.id === value);
            state.questions[index].option[optionIndex].selected = !state.questions[index].option[optionIndex].selected;
            let check_how_many_false = 0;
            state.questions[index].option.map(item => !item.selected ? check_how_many_false += 1 : null);
            if(check_how_many_false > 0){
                state.questions[index].option = state.questions[index].option.map(o => ({...o, error: true}))
            } else{
                state.questions[index].option = state.questions[index].option.map(o => ({...o, error: false}))
            }
            return state
        }
    }
})

export default attemptQuestionsSlice.reducer;
export const {
    set_questions,
    set_current_question,
    set_result_count,
    set_started,
    set_text_answer,
    set_single_option,
    set_multiple_option
} = attemptQuestionsSlice.actions;